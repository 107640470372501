<template>
    <div>
        <v-container fluid>
            <v-row class="row__search" v-if="ready">
                <v-col cols="12" class="d-flex align-center justify-space-between">
                    <h1 class="text-h4" style="flex-grow: 1">
                        {{ heading }}
                        <span v-if="ready && !loading">&nbsp;({{ personer.length }})</span>
                    </h1>

                    <v-btn color="primary" v-on:click="create">
                        {{ newBtn }}
                        <v-icon right>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-toolbar class="mt-6 d-flex" color="grey lighten-4" elevation="4">
                    <div class="mr-7">
                        <persons-actions v-model="valgtePersoner" :headers="felter" :alle="alleValgt"></persons-actions>
                    </div>
                    <div class="mr-4">
                        <v-text-field v-model="sok" label="Søk etter" append-icon="mdi-magnify" hide-details></v-text-field>
                    </div>
                    <div class="mr-4">
                        <v-select v-model="type" :items="roller" item-text="navn" item-value="id" label="Rolle" hide-details></v-select>
                    </div>
                    <div class="mr-4" v-if="type != 'KONTAKT' && type != 'FRIVILLIGIPROSESS'">
                        <v-select v-model="tjeneste" :items="tjenester" item-text="navn" item-value="id" label="Tjeneste" hide-details></v-select>
                    </div>
                    <div v-if="tjeneste == 'HJEMME'" class="mr-4">
                        <v-select
                            v-model="hjemmetjeneste"
                            :items="hjemmetjenester"
                            item-text="navn"
                            item-value="id"
                            label="Type tjeneste"
                            multiple
                            hide-details
                        ></v-select>
                    </div>
                    <div class="mr-4" v-if="type != 'KONTAKT' && type != 'FRIVILLIGIPROSESS'">
                        <v-select v-model="status" :items="statuser" item-text="navn" item-value="id" label="Status" hide-details></v-select>
                    </div>
                </v-toolbar>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <persons-data-table
                        ref="personsDataTable"
                        :personer="personer"
                        :view="type + '/' + tjeneste"
                        v-model="valgte"
                        :loading="loading"
                        v-on:edit="edit"
                        v-on:sorted="sortedList"
                        v-on:fields="fieldsInList"
                    ></persons-data-table>
                </v-col>
            </v-row>
        </v-container>
        <person-dialog ref="personDialog" v-model="person" :rolle="rolle" :personer="sortertePersoner" v-on:closed="closedDialog"> </person-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PersonsDataTable from '@/pages/persons/DataTable.vue';
import PersonsActions from '@/pages/persons/Actions.vue';
import PersonDialog from '@/pages/persons/PersonDialog.vue';

export default {
    name: 'Persons',
    props: {
        role: {
            type: String,
            required: true,
        },
    },
    components: {
        PersonsDataTable,
        PersonsActions,
        PersonDialog,
    },
    data() {
        return {
            ready: false,
            loading: false,
            valgte: [],
            personer: [],
            sortertePersoner: [],
            allePersoner: [],

            felter: [],

            type: 'BRUKER',
            tjeneste: '',
            status: '',
            sok: '',

            alleRoller: [
                { id: 'FRIVILLIG', navn: 'Frivillig' },
                { id: 'FRIVILLIGIPROSESS', navn: 'Frivillig i prosess' },
                { id: 'BRUKER', navn: 'Bruker' },
                { id: 'ANSATT', navn: 'Ansatt' },
                { id: 'KONTAKT', navn: 'Kontakt' },
            ],

            roller: [],
            tjenester: [
                { id: '', navn: '(alle)' },
                { id: 'SORG', navn: 'Sorgtjenesten' },
                { id: 'HJEMME', navn: 'Hjemmetjeneste' },
                { id: 'MOTESTED', navn: 'Møtested Oslo Øst' },
            ],

            hjemmetjeneste: [],
            hjemmetjenester: [],

            person: {},
            dialog: false,
            index: -1,
            total: 0,
        };
    },
    computed: {
        ...mapGetters('authenticate', ['user']),
        rolle: function () {
            if (this.tjeneste) {
                if (this.tjeneste == 'SORG' || this.tjeneste == 'HJEMME' || this.tjeneste == 'MOTESTED') {
                    return this.type + '_' + this.tjeneste;
                } else {
                    return this.type + '_HJEMME';
                }
            }
            return this.type;
        },
        statuser: {
            get() {
                const statuses = this.$store.getters['api/statuser'];
                statuses.unshift({ id: '', navn: '(alle utenom sluttet)' });
                return statuses;
            },
        },
        valgtePersoner: function () {
            return this.valgte.length ? this.valgte : this.sortertePersoner;
        },
        alleValgt: function () {
            return !this.valgte.length || this.valgte.length == this.personer.length;
        },

        heading: function () {
            if (this.tjeneste && this.tjeneste == 'HJEMME' && this.hjemmetjeneste.length > 0) {
                return (
                    this.$i18n.t('persons.' + this.rolle.toLowerCase() + '.list.heading_sub') +
                    ' ' +
                    this.hjemmetjenester
                        .map((tjeneste) => {
                            if (this.hjemmetjeneste.indexOf(tjeneste.id) >= 0) {
                                return tjeneste.navn.toLowerCase();
                            }
                        })
                        .filter((tjeneste) => {
                            return tjeneste != null;
                        })
                        .join(', ')
                );
            }
            return this.$i18n.t('persons.' + this.rolle.toLowerCase() + '.list.heading');
        },
        newBtn: function () {
            return this.$i18n.t('persons.' + this.rolle.toLowerCase() + '.list.new');
        },
        newRoute: function () {
            if (this.type == 'BRUKER' && this.tjeneste == 'SORG') {
                return { path: '/sorg/bruker/ny' };
            }
            const role = this.rolle.toLowerCase().split('_');
            return { path: '/' + role[0] + '/ny' };
        },
    },
    watch: {
        role: function (role) {
            const roller = role.split('_');
            this.type = roller[0];
            this.tjeneste = roller.length > 1 ? roller[1] : 0;
        },
        type: function () {
            if (this.ready && !this.loading) {
                this.update();
            }
        },
        tjeneste: function (value) {
            this.tjeneste = value;

            if (this.ready && !this.loading) {
                this.update();
            }
        },
        hjemmetjeneste: function (value) {
            this.hjemmetjeneste = value;

            if (this.ready && !this.loading) {
                this.update();
            }
        },
        status: function (value) {
            this.status = value;
            this.filter();
        },
        sok: function (value) {
            this.sok = value;
            if (this.ready) {
                this.filter();
            }
        },
    },
    filters: {
        format: function (value, format) {
            return value.format(format);
        },
    },
    created: async function () {
        switch (this.$router.currentRoute.name) {
            case '/sorg/brukere/':
                this.type = 'BRUKER';
                this.tjeneste = 'SORG';
                break;

            case '/frivillige/':
                this.type = 'FRIVILLIG';
                break;

            default: {
                const roller = this.role.split('_');
                this.type = roller[0];
                this.tjeneste = roller.length > 1 ? roller[1] : 0;
            }
        }

        this.roller = this.alleRoller;

        const response = await this.request({
            method: 'get',
            url: '/hjemmetjeneste/type',
            params: { inkluderInaktive: false },
        });

        if (response && Array.isArray(response)) {
            for (const type of response) {
                this.hjemmetjenester.push(type);
            }
        }

        await this.update();
    },
    methods: {
        ...mapActions('authenticate', ['autorize']),
        ...mapActions('api', ['request', 'formatPerson']),

        /**
         * update
         */
        update: async function () {
            try {
                if (typeof this.tjeneste == 'string' && this.tjeneste.match(/^(SORG|HJEMME|MOTESTED)/)) {
                    this.roller = this.alleRoller.slice(0, 2);
                } else {
                    this.roller = this.alleRoller;
                }

                this.loading = true;
                const response = await this.request({
                    method: 'get',
                    url: '/person/' + this.rolle.toLowerCase().replace('_', '/'),
                });
                this.allePersoner = [];
                this.personer = [];

                if (response && Array.isArray(response)) {
                    for (const person of response) {
                        this.allePersoner.push(await this.formatPerson(person));
                    }
                }

                this.filter();
                this.loading = false;
                this.ready = true;
            } catch (e) {
                console.log(e);
            }
        },

        /**
         * filter
         */
        filter: function () {
            const personer = [];
            const sok = this.sok.toLowerCase();
            const hjemmeTjeneste = this.tjeneste && this.tjeneste == 'HJEMME' ? this.hjemmetjeneste : null;

            for (const person of this.allePersoner) {
                if (
                    (!sok.length || person.sokeString.indexOf(sok) !== -1) &&
                    ((!this.status && person.status != 'SLUTTET') || (this.status && person.status == this.status)) &&
                    (!hjemmeTjeneste ||
                        !hjemmeTjeneste.length ||
                        (person.hjemmeinformasjon && person.hjemmeinformasjon.type && hjemmeTjeneste.indexOf(person.hjemmeinformasjon.type.id) >= 0))
                ) {
                    personer.push(person);
                }
            }
            this.personer = personer;
        },

        /**
         * edit
         */
        edit: function (index) {
            this.$refs.personDialog.edit(index);
        },

        /**
         * sortedList
         */
        sortedList: function (list) {
            this.sortertePersoner = list;
        },

        /**
         * fieldsInList
         */
        fieldsInList: function (fields) {
            this.felter = fields;
        },

        /**
         * closedDialog
         */
        closedDialog: async function (arg) {
            await this.update();
            if (arg) {
                const id = arg.id;
                for (let index = 0; index < this.sortertePersoner.length; index++) {
                    if (this.sortertePersoner[index].id == id) {
                        this.edit(index);
                        break;
                    }
                }
            }
        },

        /**
         * create
         */
        create: function () {
            this.$refs.personDialog.create();
        },
    },
};
</script>
<style lang="scss">
.v-dialog__content:has(.v-dialog--overlay) {
    //z-index: 5 !important;
}

.v-dialog--fullscreen {
    background-color: #fff;
}
</style>
